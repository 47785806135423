import React, {useEffect} from "react"
import {Navigate, Outlet} from "react-router-dom"
import {useAuth} from "../contexts/AuthContext"

export default function PrivateRoute({component: Component, ...rest}) {
  const {currentUser,loading} = useAuth()
  useEffect(() => {
      console.log(currentUser)
      console.log(loading)

  }, []);
  return currentUser || loading ? <Outlet/> : <Navigate to="/login"/>
}
