import {useEffect} from 'react';

const useClickOutside = (ref, callbackFunction) => {
  // implement the callback ref pattern
  useEffect(() => {

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        console.log(event.target)
        callbackFunction(event)
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export default useClickOutside;
