import React, {useEffect, useRef, useState} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {CircularProgress, InputAdornment} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import fetchApi from "../utils/http";
import {ReactComponent as ReactSearch} from '../assets/search.svg';
import {useKeyPress} from '../hooks/useKeyPress';
import useClickOutside from '../hooks/useClickOutside'


function Search({setData, setQueryF, setSuggestF, query, setQuery, fromClick}) {
  const [suggest, setSuggest] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const inputRef = useRef("")
  const wrapperRef = useRef(null);
  const shit = useRef();


  useClickOutside(wrapperRef, () => setOpen(false))


  const onKeyPress = (event) => {
    if (document.activeElement === inputRef.current) {
    } else if (event.ctrlKey) {
      event.preventDefault()
      inputRef.current.focus()
    }
  };
  useKeyPress(['f'], onKeyPress);


  useEffect(() => {
    if (fromClick === query) {
      search(query)
    }
  }, [fromClick, query])

  useEffect(() => {
    const abortController = new AbortController();
    if (query && query.length > 3) {
      setTimeout(() => {
        setLoading(true)
        fetchApi(`/api/suggest?s=${query}`, {signal: abortController.signal})
          .then(response => {
            if (response && response.ok) {
              return response.json()
            } else {
              return {}
            }
          })
          .then(data => {
            if (data.length > 0) {
              if (document.activeElement === inputRef.current) {
                setOpen(true)
              }
              setSuggest(data)
            }

            setLoading(false)
          })
          .catch(error => {
            console.error(error)
          })

      }, 500, {signal: abortController.signal})
    } else {
    }
    return () => abortController.abort() && setLoading(false);
  }, [query])


  function search(q) {
    setOpen(false)
    setSuggestF(null)
    setLoading(true)
    // setData([])
    setQueryF(q)


    fetchApi(`/api/search?q=${q}`)
      .then(response => response.json())
      .then(data => {
        if (data.length === 0) {
          setData([]);
        } else setData(data)
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(_ => setLoading(false))
  }

  const handleOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.target.blur()
      setQuery(event.target.value)

      search(event.target.value)
    }
  }
  const handleOnInputChange = (event) => {
    event.preventDefault()
    setQuery(event.target.value)
  };

  function onItemClick(event) {
    inputRef.current.blur()
    setOpen(false)
    setQuery(event.target.innerText)
    search(event.target.innerText)

  }

  return (

    <div className="search">

      <Autocomplete
        ref={shit}
        getOptionLabel={option => option.word ? option.word : ''
        }
        autoComplete={true}
        // getOptionSelected={getOptionValue}
        filterOptions={x => x}
        options={suggest}
        onKeyDown={handleOnKeyDown}
        inputValue={query}
        onClose={() => setOpen(false)}
        onOpen={() => suggest.length > 0 ? setOpen(true) : setOpen(false)}
        open={open}
        renderInput={params => (
          <TextField
            {...params}
            inputRef={inputRef}
            variant="outlined"
            fullWidth
            id={'search'}
            onChange={handleOnInputChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20}/> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <ReactSearch/>
                </InputAdornment>)
            }}
          />
        )}
        renderOption={(option, {selected, inputValue}) => {
          return (
            option.highlight &&
            <Grid container alignItems="center"
                  tabIndex={0}
                  onClick={onItemClick}
                  style={{
                    alignItems: "center", justifyContent: "center", display: "flex",
                    paddingTop: "0px", overflow: "hidden"
                  }}>
              <Grid item
              >
                <ReactSearch/>
              </Grid>
              <Grid item xs
                    onKeyDown={(event) => {
                    }}
                    style={{marginLeft: "1rem"}}>
                <span className="" dangerouslySetInnerHTML={{__html: option.highlight}}/>
              </Grid>
            </Grid>
          );
        }}
      />
    </div>
  );
}

export default Search;
