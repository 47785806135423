import {SiTodoist} from 'react-icons/si';
import {FcGoogle} from 'react-icons/fc';
import {IoPerson} from 'react-icons/io5';

import {useAuth} from "../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const SignIn = () => {
  const {login, loggedIn,loading,currentUser} = useAuth()
  const navigate = useNavigate()
  const handleSignIn =  () => {
    try {
      login()
      console.log("logged in")
      // navigate("/")
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    console.log(loggedIn)
    if (currentUser) {
      navigate("/")
    }
  }, [currentUser])

  if (loading) {
    return (
      <span className="loading loading-dots loading-lg flex item-center mx-auto"></span>
    );
  }

  return (

    <div className='auth'>
      <div className='auth__container'>
        <div className='auth__brand'>
          <SiTodoist/>
          <span>YT-Engine</span>
        </div>

        <div className='auth__title'>

          <h1 className='auth__title'>Log in</h1>
          <button
            onClick={() => handleSignIn('google')}
            className='auth__providerBtn button__secondary'
            data-cy='signIn__google'>
          <span>
            <FcGoogle/>
          </span>
            Continue with Google
          </button>
        </div>
        <div className='auth__dividerText'>
          <span className='whiteDashes'>---</span>
        </div>
        <div className='auth__agreement'>
          <span>
            by signing in you agree to our terms of service and privacy policy
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
