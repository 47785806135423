import React from 'react';

const youtubeThumbnail = require('youtube-thumbnail');


const YtHit = (props) => {

  function onClick() {
    const url = `${props.hit.URL}?t=${parseInt(props.hit.start)}`
    console.log(url)
    window.open(`${props.hit.URL}&t=${parseInt(props.hit.start)}`, '_blank');
  }

  return (<>
    <article className="hit" onClick={onClick}>
      <header className="hit-image-container">
        <img
          src={youtubeThumbnail(props.hit.URL).medium.url}
          className="hit-image" alt={""}>
        </img>
      </header>
      <div className="hit-info-container">
        <h1>
            <span
              className="ais-Highlight">
              <span>
                {props.hit.title}
              </span>
            </span>
        </h1>

        <p className="hit-description">
              <span className="ais-Snippet-nonHighlighted" dangerouslySetInnerHTML={{ __html: props.hit.highlight }} />
        </p>


      </div>

    </article>
    <footer>
      <p>
          <span className="hit-em hit-rating">
            {new Date(parseInt(props.hit.time)).toLocaleDateString()}
          </span>
      </p>
    </footer>
  </>)
}

export default YtHit;
