import {SidebarProvider} from "../contexts/SidebarContext";

const Page = ({children}) => {
  return (
    // <div className='page' data-cy='page'>
    //   <SidebarProvider>
    //     <Header/>
    //   </SidebarProvider>
    //   <div className='content'>{children}</div>
    // </div>
    children
  );
};

export default Page;
