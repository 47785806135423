function fetchApi(url, options) {
  return fetch(url, options)
    .then(response => {
      if (response.redirected) {
        window.location.href = response.url;
      } else
        return response
    }).catch(error => {
      if (!(error instanceof DOMException)) {
        console.error(error)
      }
    })
}

export default fetchApi
