import React, {useEffect, useState} from 'react';
import '../Theme.css';
import '../App.css';

// import YtHit from "./YtHit.jsx";
// import {NoMatchQuery} from "./NoMatchQuery";
// import Search from "./Search";
import Page from "./Page";
// import Profile from "./Profile";
// import fetchApi from "../utils/http";
import AnimatedNumber from 'react-animated-number';
// import {Typography} from "@material-ui/core";
// import {useAuth} from "../contexts/AuthContext";
import Search from "./Search";
import {NoMatchQuery} from "./NoMatchQuery";
import YtHit from "./YtHit";
import {useAuth} from "../contexts/AuthContext";
import fetchApi from "../utils/http";


const Home = () => {
  const [queryF, setQueryF] = useState(false)
  const [sort, setSort] = useState('')
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const [user, setUser] = useState({watched_videos: 0})
  const [suggest, setSuggest] = useState(null)
  const [query, setQuery] = useState('')
  const [key, setKey] = useState(false)
  const {currentUser} = useAuth()

  // const ref = React.useRef();
  //
  // const onLoad = () => {
  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 1000)
  // };
  //fetch user info
  useEffect(() => {
  }, [])


  return (
    <Page>
      <div>
        {console.log(currentUser)}
        <header className="header">
          <p className="header-title">Search for any Youtube video you watched.</p>
          {/*<b/>*/}
          <p className="header-subtitle"> We indexed
            <AnimatedNumber
              duration={user['watched_videos'] === 0 ? 2000 : 1500}
              style={{
                transition: '0.4s ease-out',
                fontSize: 28,
                transitionProperty:
                  'background-color, color, opacity'
              }}
              frameStyle={perc => (
                perc === 100 ? {} : {}
              )}
              stepPrecision={0}
              value={currentUser['indexed_videos']}
              formatValue={n => ` ${n} ` +
                ''}/> of your watched videos.
          </p>

          <Search
            fromClick={key}
            setData={setData}
            setSuggestF={setSuggest}
            setQueryF={setQueryF}
            setQuery={setQuery}
            query={query}
          />

        </header>

        <main className="container">

          {data && data.length === 0 && <NoMatchQuery/>}

          {data &&
            <>
              <section className={"container-options container-header"}>
                {data &&
                  <>
                    <div className="header-result">
                      {suggest &&
                        <p style={{marginBottom: "15px", fontSize: "16px", fontWeight: 400}}>
                          Did you mean:
                          {" "}<a className="container-header-suggest" onClick={suggestClicked}>
                          {suggest.suggest}
                        </a>
                        </p>}
                      <p className="container-header-query">
                        {data.length} results for:
                        <a>
                          {" " + queryF}
                        </a>
                      </p>

                    </div>

                    <div className="container-option-text ais-SortBy">
                      <select className="ais-HitsPerPage-select" onChange={(event) => {console.log(event)}} value={sort}>
                        <option className="ais-HitsPerPage-option">relevance</option>
                        <option
                          className="ais-HitsPerPage-option">date
                        </option>
                      </select>
                    </div>
                  </>
                }
              </section>

              <section className="container-results">

                <div className="ais-Hits">
                  <div className="ais-Hits-list">
                    {data?.map((hit, index) =>
                      <div key={index} className="ais-Hits-item">
                        <YtHit
                          hit={hit}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div style={{height: "100px", "borderBottom": "1px solid #ebecf3"}}/>

              </section>
            </>
          }
          {/*<section>*/}
          {/*  <Typography variant="h3" component="h3" style={{"margin": "1rem"}}>*/}
          {/*    Dashboard*/}
          {/*  </Typography>*/}
          {/*  {loading && <div className={"loading-dashboard"}>*/}
          {/*    <CircularProgress/>*/}
          {/*  </div>}*/}
          {/*{key && <div className="container-results-shadow">*/}
          {/*in a frame because it set 'X-Frame-Options' to 'deny'.*/}
          {/*<iframe*/}
          {/*  id={"iframe"}*/}

          {/*  src={`/dashapp/sales`} height={"970px"}*/}
          {/*  scrolling={"yes"}*/}
          {/*  style={{"width": "100%"}} frameBorder={0}/>*/}
          {/*</div>*/}
          {/*}*/}
        </main>
      </div>
    </Page>);
};

export default Home;
