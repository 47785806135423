import React, {useContext, useEffect, useState} from "react"
import {auth, signInWithGoogle} from "../firebase"
import fetchApi from "../utils/http";
import {CircularProgress} from "@material-ui/core";

const AuthContext = React.createContext({
  currentUser: null,
  login: () => {
  },
  logout: () => {
  },
  loggedIn: false,
  loading: true
})

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({children}) {
  const [currentUser, setCurrentUser] = useState()
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)


  async function login() {
    console.log("logging in")
    setLoading(true)
    await signInWithGoogle(setLoggedIn)
  }

  function logout() {

    return auth.signOut().then(() => {
      fetch("/api/logout", {
        method: "POST"
      }).then(r => setLoggedIn(false))
    })
  }

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(user => {
      console.log(user)
      if (user) {
        setLoading(true)
        // call backend to fetch user info
        fetchApi("/api/user", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + user['token']
          }
        }).then(response => {
          if (response && response.ok) {
            return response.json()
          } else {
            return {}
          }
        }).then(data => {
          console.log(data)
          setCurrentUser({...user, ...data})
          setLoading(false)
        }).catch(error => {
          console.error(error)
          setLoading(false)
        }).finally(() => {
          setLoggedIn(false)
        })

      } else {
        setCurrentUser(null)
        setLoading(false)
      }

      return () => unsub()


    })
  }, [])

  const value = {
    currentUser,
    login,
    logout,
    loggedIn,
    loading
  }

  return (
    <AuthContext.Provider value={value}>

      {console.log('cooool')}
      {loading ? <CircularProgress
        style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}
        color="inherit" size={40}/> : null}
      {!loading && children}
    </AuthContext.Provider>
  )
}
