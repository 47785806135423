import React from "react"
import {AuthProvider, useAuth} from "./contexts/AuthContext";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import Home from "./components/Home"
import PrivateRoute from "./components/PrivateRoute"
import SignIn from "./components/SignIn";


function Logout() {
  const {logout} = useAuth()
  return (logout().then(() => {
    window.location.href = "/login"
  }));
}


const App = () => {

  return (
    <div className='App'>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path={"/login"} element={<SignIn/>}>

            </Route>

            <Route path="/" element={<PrivateRoute component={<Home/>}/>}>
              <Route path="" element={<Home/>}/>
              <Route path="/logout" element={<Logout/>}/>
            </Route>

          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
};

export default App;
