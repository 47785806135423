import * as firebase from 'firebase/app';
import {GoogleAuthProvider, getAuth, signInWithPopup} from 'firebase/auth';
import 'firebase/compat/auth'

const app = firebase.initializeApp({
  // REACT_APP_FIREBASE_API_KEY="AIzaSyD8aTDMxVtwpy5wgyNMh6eoK8hg7wipSzQ"
  // REACT_APP_FIREBASE_AUTH_DOMAIN="yt-engine-app.firebaseapp.com"
  // REACT_APP_FIREBASE_DATABASE_URL="yt-engine-app"
  // REACT_APP_FIREBASE_PROJECT_ID="yt-engine-app.appspot.com"
  // REACT_APP_FIREBASE_STORAGE_BUCKET="148378051937"
  // REACT_APP_FIREBASE_MESSAGING_SENDER_ID="1:148378051937:web:c4af9044f04ed1e7335120"
  // REACT_APP_FIREBASE_APP_I="G-WCPD6QCNST"
  // REACT_APP_DASH_URL="http://100.98.101.44:3000"
  apiKey: "AIzaSyD8aTDMxVtwpy5wgyNMh6eoK8hg7wipSzQ",
  authDomain: 'yt-engine-app.firebaseapp.com',
  databaseURL: 'yt-engine-app',
  projectId: 'yt-engine-app.appspot.com',
  storageBucket: '148378051937',
  messagingSenderId: '1:148378051937:web:c4af9044f04ed1e7335120',
  appId: 'G-WCPD6QCNST'
})

// get auth
export const auth = getAuth(app)

export const signInWithGoogle = (setLoggedin) => signInWithPopup(auth, new GoogleAuthProvider())
  .then((user) => {
    user.user.getIdToken()
      .then((token) => {
        fetch("/api/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
          }
        }).then(user => {
          setLoggedin(true)
        })
          .catch((error) => {
            console.error(error)
          })
      })

    return user
  })
;

export default app

// get GoogleAuthProvider provider
